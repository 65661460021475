import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import DownloadLink from 'components/csv/DownloadLink';
import FullPageLoader from 'components/loading/FullPageLoader';
import CampaignFilter from 'components/search/CampaignFilter';
import DateFilter from 'components/search/DateFilter';
import OrgFilter from 'components/search/OrgFilter';
import Search from 'components/search/Search';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import log from 'util/log';

const StyledDateFilter = styled(DateFilter)`
  margin-right: 15px;
`;

const StyledOrgFilter = styled(OrgFilter)`
  margin-right: 15px;
`;

const StyledCampaignFilter = styled(CampaignFilter)`
  margin-right: 15px;
`;

export default class ArchiveTable extends Component {
  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);

    this.handleCampaignFilterChange = this.handleCampaignFilterChange.bind(
      this
    );
    this.handleOrgFilterChange = this.handleOrgFilterChange.bind(this);

    this.state = {
      defaultColDef: {
        filter: true,
        resizable: true
      },
      columnDefs: [
        {
          headerName: 'DOI',
          field: 'doi',
          width: 170,
          cellRenderer: function (params) {
            const doi = params.value;

            // legacy - not migrated to series id
            const { legacy, obsolete } = params.data;

            let obsoleteLink = '';
            if (obsolete) {
              const icon =
                '<span title="this archive is stale" style="opacity:.5;margin-left:3px" role="img" aria-label="cloud" class="anticon anticon-cloud"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="cloud" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M811.4 418.7C765.6 297.9 648.9 212 512.2 212S258.8 297.8 213 418.6C127.3 441.1 64 519.1 64 612c0 110.5 89.5 200 199.9 200h496.2C870.5 812 960 722.5 960 612c0-92.7-63.1-170.7-148.6-193.3z"></path></svg></span>';

              obsoleteLink = ArchiveTable.getArchiveLink(params.data, icon);
            }

            const html = `
              <a href="https://doi.org/${doi}" target="_blank" rel="noopener noreferrer" class="doi ${
              legacy ? 'legacy' : ''
            }">
                ${doi}
              </a>
              ${obsoleteLink}
            `;

            return html;
          }
        },
        {
          headerName: 'Citation',
          // field: 'citation',
          width: 300,
          cellRenderer: function (params) {
            const { data } = params;
            const { citation } = data;

            return ArchiveTable.getArchiveLink(data, citation);
          }
        },
        {
          headerName: 'Project',
          field: 'project',
          width: 300,
          cellRenderer: function (params) {
            const project = params.value;

            return `<a href="https://researchworkspace.com/project/${project.id}/files" target="_blank" rel="noopener noreferrer" class="project">${project.name}</a>`;
          }
        },
        {
          headerName: 'Folder',
          field: 'folder',
          width: 300,
          cellRenderer: function (params) {
            const folder = params.value;

            if ( typeof folder !== 'undefined' && !!folder ) {
                return `<a href="https://researchworkspace.com${folder.url}" target="_blank" rel="noopener noreferrer" class="folder">${folder.title}</a>`;
            } else {
                return '<span/>';
            }
          }
        },
        {
          headerName: 'Submitted',
          field: 'submitted',
          sort: 'desc',
          width: 100
        },
        {
          headerName: 'Indexed',
          field: 'indexed',
          sort: 'desc'
        },
        {
          headerName: 'Type',
          field: 'type',
          width: 80
        },
        {
          headerName: 'URL',
          field: 'url'
        }
      ],
      rowData: [],
      loaded: false
    };
  }
  /*
  static getDerivedStateFromProps(props, currentState) {
    // log.info('getDerivedStateFromProps %o %o', props, currentState);

    const { start, end } = props;

    if (start !== currentState.dateFilter.start) {
      return {
        ...currentState,
        dateFilter: {
          start,
          end
        }
      };
    }

    return null;
  }
  */

  static getArchiveLink(data, content) {
    const { id } = data;

    const url = `/archive/${id}`;

    return `<a href="${url}" class="project">${content}</a>`;
  }

  handleDateFilterChange(filter) {
    log.info('handleDateFilterChange %o', filter);

    const { initialData, textFilter, orgFilter, campaignFilter } = this.state;

    let data = initialData;
    data = this.getFilteredData({
      text: textFilter,
      campaign: campaignFilter,
      org: orgFilter,
      dateFilter: filter
    });

    log.info('set dateFilter state to %o', filter);

    this.setState({
      ...this.state,
      dateFilter: filter,
      rowData: data
    });
  }

  handleCampaignFilterChange(id) {
    log.info('handleCampaignFilterChange %s, state: %o', id, this.state);

    // get existing filters

    const { textFilter, dateFilter, orgFilter } = this.state;

    const { initialData } = this.state;

    const data = this.getFilteredData({
      campaign: id,
      org: orgFilter,
      text: textFilter,
      dateFilter
    });

    this.setState({
      ...this.state,
      campaignFilter: id,
      rowData: data
    });

    log.info('filtered %s down to %s', initialData.length, data.length);
  }

  handleOrgFilterChange(id) {
    log.info('handleOrgFilterChange %s', id);

    // get existing filters

    const { textFilter, campaignFilter, dateFilter } = this.state;

    const { initialData } = this.state;
    let data = initialData;
    if (id !== undefined) {
      data = this.getFilteredData({
        org: id,
        campaign: campaignFilter,
        text: textFilter,
        dateFilter
      });
    }

    this.setState({
      ...this.state,
      orgFilter: id,
      rowData: data
    });
  }

  getFilteredData(options) {
    let { initialData: data } = this.state;

    log.info('getFilteredData: %o', options);

    const { dateFilter, text, campaign, org } = options;
    if (dateFilter && dateFilter.start && dateFilter.end) {
      log.info('filtering by date range %o', dateFilter);
      const { start, end } = dateFilter;
      data = this.getFilteredDateRange(start, end, data);
    }

    if (text) {
      log.info('filtering by text %s', text);
      data = this.getFilteredText(text, data);
    }

    if (campaign) {
      log.info('filtering by campaign %s', campaign);
      data = this.filterCampaigns(campaign, data);
    }

    if (org) {
      log.info('filtering by org %s', org);
      data = this.filterOrgs(org, data);
    }

    return data;
  }

  filterCampaigns(id, initialData) {
    return initialData.filter(record => {
      const { campaigns } = record;

      if (campaigns) {
        return campaigns.includes(id);
      }

      return false;
    });
  }

  filterOrgs(id, initialData) {
    return initialData.filter(record => {
      const { orgs } = record;

      if (orgs) {
        return orgs.includes(id);
      }

      return false;
    });
  }

  getFilteredDateRange(start, end, initialData) {
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    const filtered = initialData.filter(record => {
      return startDate.isBefore(record.date) && endDate.isAfter(record.date);
    });
    log.info(
      'initial rowData length: %s, filtered length: %s',
      initialData.length,
      filtered.length
    );
    return filtered;
  }

  getFilteredText(textFilter, initialData) {
    if (!textFilter || textFilter.length === 0) {
      return initialData;
    }
    return initialData.filter(function (item) {
      const searchFields = [
        item.citation,
        item.project.name,
        // Handle case when folder property is missing
        (
            ( typeof item.folder !== 'undefined' && !!item.folder )
            ? item.folder.title
            : ''
        ),
        item.doi,
        item.indexed
      ];

      let text = '';
      searchFields.forEach(field => {
        if (field) {
          text += field.toLowerCase();
        }
      });

      return text.includes(textFilter.toLowerCase());
    });
  }

  handleFilterChange(textFilter) {
    const { orgFilter, campaignFilter, dateFilter } = this.state;

    const data = this.getFilteredData({
      org: orgFilter,
      campaign: campaignFilter,
      text: textFilter,
      dateFilter
    });

    this.setState({
      rowData: data,
      textFilter: textFilter
    });
  }

  componentDidMount() {
    document.title = 'Archives | Research Workspace';

    axios.get('https://researchworkspace.com/api/archives').then(result => {
      const { data } = result;

      // save submitted as date for later filtering
      for (const record of data) {
        const { submitted } = record;
        record.date = new Date(submitted);
      }

      this.setState({
        rowData: data,
        initialData: data,
        loaded: true
      });
    });
  }

  render() {
    const { rowData, textFilter, loaded } = this.state;

    if (!loaded) {
      return <FullPageLoader />;
    }

    return (
      <div
        className="ag-theme-balham"
        style={{
          height: '500px',
          width: '100%'
        }}
      >
        <div id="header">
          <a id="rw-logo" href="https://researchworkspace.com">
            <img
              alt="Research Workspace"
              src="https://researchworkspace.com/public/images/logo/delta_padded.png"
            />
          </a>
          <h1>Archives ({rowData.length})</h1>
          <Search
            filter={textFilter}
            onFilterChange={this.handleFilterChange}
          ></Search>
          <StyledCampaignFilter onChange={this.handleCampaignFilterChange} />
          <StyledOrgFilter onChange={this.handleOrgFilterChange} />
          <StyledDateFilter onChange={this.handleDateFilterChange} />
          <DownloadLink data={rowData} />
        </div>
        <AgGridReact
          defaultColDef={this.state.defaultColDef}
          columnDefs={this.state.columnDefs}
          rowData={rowData}
          enableCellTextSelection={true}
          domLayout="autoHeight"
        ></AgGridReact>
      </div>
    );
  }
}
